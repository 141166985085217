<template>
  <div class="contact-content">
    <div class="contact-img"
         @click="clickContact">
      <img class="qrcode-img"
           :src="qrCode" />
    </div>
    <div class="make-phone"
         @click="makePhone">
      <div class="make-phone-content">
        <img class="make-phone-img"
             src="https://wechatdn.yiautos.com/channel_query/v1/icon_phone.png" />
        <div class="bold">拨打电话</div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    qrCode: {
      type: String,
      required: true,
    },
    servicePhone: {
      type: String,
      required: true,
    },
  },
  setup (props, context) {
    function clickContact () {
      // @ts-ignore
      window.sensorsDataAnalytic201505.track("inquiry_h5_tap_event", {
        type: "客服二维码图片",
      });
    }
    function makePhone () {
      const phone = props.servicePhone;
      window.location.href = `tel:${phone}`;
    }
    return {
      clickContact,
      makePhone,
    };
  },
});
</script>

<style lang="less" scoped>
@import "../../../styles/common.less";
.contact-content {
  .flexible(column, center, center);
  .contact-img {
    width: 6.9rem;
    height: 2.6rem;
    background-image: url("https://wechatdn.yiautos.com/channel_query/v1/contact_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 6.9rem 2.6rem;
    position: relative;
    margin: 0.3rem;
    .qrcode-img {
      width: 1.9rem;
      height: 1.9rem;
      position: absolute;
      right: 0.4rem;
      top: 0.35rem;
    }
  }
  .make-phone {
    .flexible(row, center, center);
    width: 6.9rem;
    height: 0.8rem;
    background: #ffb500;
    border-radius: 0.4rem;
    color: #242b30;
    .make-phone-content {
      .flexible(row, center, center);
    }
    .make-phone-img {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.3rem;
    }
    .bold {
      font-weight: bold;
    }
  }
}
</style>

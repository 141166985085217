
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import contact from "./contact.vue"; //联系方式
export default defineComponent({
  props: {
    qrCode: {
      type: String,
      required: true,
    },
    servicePhone: {
      type: String,
      required: true,
    },
  },
  components: {
    contact,
  },
  setup() {
    // 返回询价主页
    const router = useRouter();
    function inquiry() {
      router.push({ path: "/inquiry-index" });
    }
    return {
      inquiry,
    };
  },
});
